import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import _, {isArray} from 'lodash';
import {Loader, Container, Dimmer, Divider, Header, Segment, Checkbox} from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroll-component';

import {fetchOrders, ORDERS_PAGE_COUNT} from "./actions";
import {getOrders, getOrdersFetching, getOrdersHasMore} from "./reducer";
import OrdersList from "../../components/OrdersList";

import "./style.css"
import InfiniteView from "../../components/InfiniteView";
import {useParams} from "react-router";
import {Base64} from "../../App";

export default function Orders(props) {

  const {dispatch, userData, userLocation, mixpanel, routeAction, token, isPageReloaded, location} = props;

  const loading = useSelector((state) => getOrdersFetching(state.orders));
  const orders = useSelector((state) => getOrders(state.orders));
  const hasMore = useSelector((state) => getOrdersHasMore(state.orders));

  const [showCompleted, setShowCompleted] = useState(false);

  let searchObj = {};

  if (location.search.length) {
    const searchStr = location.search.slice(1);
    for (let entry of searchStr.split(";")) {
      let pair = entry.split("=");
      searchObj[pair[0]] = pair[1];
    }
  }

  useEffect(() => {
    if ( routeAction === 'PUSH' || isPageReloaded ) {
      if (token)
        readOrders(1);
        window.scrollTo(0, 0);
    }
    if (mixpanel)
      mixpanel.track('Orders List View', { ...userLocation, ...userData });
  }, [showCompleted]);

  useEffect(() => {
    if (isPageReloaded)
      window.scrollTo(0, 0);
  }, [props]);

  const loadOrders = () => {
    if (hasMore) {
      const nextPage = Math.round(orders.length / ORDERS_PAGE_COUNT) + 1;
      readOrders(nextPage);
    }
  };

  const readOrders = (page) => {
    const params = {
      page,
      order: 'desc',
      orderby: 'id',
      per_page: ORDERS_PAGE_COUNT,
    };

    if (searchObj.filter) {
      params['filter'] = searchObj.filter;
      params['order'] = 'asc';
    }

    if (!showCompleted)
      params['filter_addition'] = Base64.encode("order_status = 'processing'");

    dispatch(fetchOrders(token, params));
  };

  const elementFilter = (
      <Segment className="order-filter-segment">
          <Checkbox
              className="filter-item-checkbox"
              toggle
              label="Показывать завершенные"
              defaultChecked={showCompleted}
              onChange={() => setShowCompleted(!showCompleted)}
          />
      </Segment>
  );

  if (loading) {
    return (
        <Dimmer active={ loading } inverted className="page-loader">
          <Loader>Загружаем отвесы ...</Loader>
        </Dimmer>
    );
  }

  if (orders.length === 0 || !token) {
    return (
        <Container>
            <p>
                {token
                    ? <>
                        <div className="filter-nothing-found-text">Отвесы отсутствуют</div>
                        {elementFilter}
                    </>
                    : 'Для получения доступа к списку, пожалуйста, авторизуйтесь'
                }</p>
        </Container> );
  }

  return (
      <>
          <span className='orders-text'>Отвесы</span>
          <InfiniteView
              dataLength={orders.length}
              nextFetch={loadOrders}
              hasMore={hasMore}
              dataList={(
                  <OrdersList
                      orders={orders}
                      elementFilter={elementFilter}
                      {...props}
                  />
              )}
              mustAuth={true}
              isAuth={!!token}
              isLoading={loading}
              loaderText="Загружаем отвесы ..."
              zeroText="Отвесы отсутствуют"
              elementFilter={(
                  <Segment className="order-filter-segment">
                    <Checkbox
                        className="filter-item-checkbox"
                        toggle
                        label="Показывать завершенные"
                        defaultChecked={showCompleted}
                        onChange={() => setShowCompleted(!showCompleted)}
                    />
                  </Segment>
              )}
          />
        </>
  );

}

