import React from 'react';
import { Header } from 'semantic-ui-react';
import _ from "lodash";
import OrderGroupedCard from "./OrderCard/OrderGroupedCard/OrderGroupedCard";

export default function OrdersGroupedList(props) {

  const { title, orders, elementFilter } = props;
  const list = orders.map((element, index) => {
      if (_.isEmpty(element)) return;
      return (
          <OrderGroupedCard
              key={index}
              order={element}
              circular={true}
              {...props}
          />);
  });

  return (
      <div>
        <Header textAlign="center">{title}</Header>
        {_.isUndefined(elementFilter) ? '' : elementFilter}
        {list}
      </div>
    );
}
