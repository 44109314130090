import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const DoubleLineChart = () => {
    // Данные для графика
    const data = {
        labels: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
        datasets: [
            {
                label: 'Линия 1',
                data: [30, 20, 50, 40, 70, 60, 90, 80, 100, 90, 120, 110],
                borderColor: '#6DCBFF',
                backgroundColor: '#6DCBFF',
                fill: true,
                pointRadius: 3,
            },
            {
                label: 'Линия 2',
                data: [40, 35, 39, 35, 50, 60, 57, 62, 47, 10, 30, 58],
                borderColor: '#AA0000',
                backgroundColor: '#AA0000',
                fill: true,
                pointRadius: 3,
            },
        ],
    };

    // Опции графика
    const options = {
        responsive: true,
        scales: {
            x: {
                title: {
                    display: false,
                    text: 'Месяц',
                },
            },
            y: {
                title: {
                    display: false,
                    text: 'Значения',
                },
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                position: 'top',
                display: false,
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
    };

    return (
        <Line data={data} options={options} />
    );
};

export default DoubleLineChart;
