import { Link } from 'react-router-dom';
import React, {useRef, useState} from 'react';
import {
  Card, Button, Header, Grid, Icon, Divider, Modal, Dimmer, Loader,
} from 'semantic-ui-react';
import './styles.css';
import config from '../../config/config';
import CircularImage from '../CircularImage';
import {toastr} from "react-redux-toastr";
import {addProduct} from "../../views/Cart/actions";
import {useSelector} from "react-redux";
import {getCart} from "../../views/Cart/reducer";
import {ProductLabelToPrint} from "../PrintComponent/PrintComponent";
import {useReactToPrint} from "react-to-print";
import {createProductQRCode} from "../../views/Products/actions";
import {getProductsDML} from "../../views/Products/reducer";

export default function ProductCard(props) {

  const cart =  useSelector( (state) => getCart(state.cart));
  const loading = useSelector((state) => getProductsDML(state.products));

  const [openLabelModal, setOpenLabelModal] = useState(false);

  const {
    categories: propCat, id, src, categoryId, name, shortDescription, price, has_options, virtual,
      dispatch, userData, userLocation, mixpanel, qrcode, token, navigate
  } = props;

  //console.log(props);

  const product_in_cart = !!cart.find((obj) => obj.id === id,);
  const categories = propCat.map((category) => category.name);

  function addItem() {
    dispatch(
        addProduct(id,
            name,
            price,
            src,
            virtual,
            null,
            null,
        ),
    );
    toastr.success('Добавление в корзину', name + ' добавлен в корзину');
    if (mixpanel)
      mixpanel.track('Add To Cart', { ...userLocation, ...userData, id, product_name: name, price, src, virtual, });
  }

  function printProductLabel() {
    setOpenLabelModal(true);
  }

  function createProductLabel() {

    if (qrcode === '') {
      const formObj = {
        user_location: JSON.stringify({
          userData: {id: userData.id, login: userData.login},
          userLocation,
          //currentExpoLocation,
        }),
      }
      dispatch(createProductQRCode(token, id, formObj));
    }

    setOpenLabelModal(true);

    if (mixpanel)
      mixpanel.track('ProductCard Create QR-code', { /*...currentExpoLocation,*/ ...userLocation, ...userData, product_id: id });
  }

  let componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const modalProductLabel = () => (
      <Modal
          className="modal-order-badge"
          closeIcon
          open={openLabelModal}
          onClose={()=>setOpenLabelModal(false)}
          onOpen={()=>setOpenLabelModal(true)}
          dimmer
          centered
          size="fullscreen"
          closeOnDimmerClick={true}
      >
        <Header icon="id badge" content={`Этикетка для продукта`} />
        <Modal.Content>
          <Dimmer active={ loading === id } inverted>
            <Loader inverted />
          </Dimmer>
          <Card className="order-badge" centered color="orange" raised>
            <Card.Content>
              <ProductLabelToPrint id={id} ref={componentRef} name={name} product_qrcode={qrcode}/>
            </Card.Content>
          </Card>
        </Modal.Content>
        <Modal.Actions>
          <Button color='red' onClick={()=>setOpenLabelModal(false)}>
            <Icon name='remove' /> Закрыть
          </Button>
          <Button color='green' onClick={handlePrint} disabled={false}>
            <Icon name='checkmark' /> Печать
          </Button>
        </Modal.Actions>
      </Modal>
  );

  return (

      <div className='component-card'>
        <div className='component-card-header'>
          <img src={src} alt={name} onClick={() => navigate(`/product/${id}`)} className='component-card-icon'/>
          <div className='component-card-text-container'>
            <div className='component-card-title'>{name}</div>
            <div className='component-card-categories'
                 onClick={() => navigate(`/products/${categoryId}`)}>{categories.join(', ')}</div>
            <div className='component-card-description' dangerouslySetInnerHTML={{__html: shortDescription}}></div>
          </div>
        </div>
        <div className='components-card-btn-container'>
          {has_options ?
              <button className='component-card-btn btn-blue' onClick={() => navigate(`/product/${id}`)}>Выбрать
                опции</button>
              :
              <>
                <button className='component-card-btn btn-blue' onClick={createProductLabel}>Напечатать этикетку
                </button>
                {modalProductLabel()}
                <button className='component-card-btn btn-gray' onClick={() => navigate(`/product/${id}`)}>Подробнее
                </button>
              </>
          }
        </div>
      </div>
      // <Card centered color="blue" raised>
      //   <Card.Content>
      //     <Grid>
      //       <Grid.Row centered key={id}>
      //         <Grid.Column width={4}>
      //           <Link to={'/product/' + id}>
      //             <CircularImage src={src} circular={false}/>
      //           </Link>
      //         </Grid.Column>
      //         <Grid.Column width={12} textAlign="center">
      //           <Card.Header as="h3" className="break-words">{name}</Card.Header>
      //           <Link to={'/products/' + categoryId}>
      //             <Card.Meta>{categories.join(', ')}</Card.Meta>
      //           </Link>
      //         </Grid.Column>
      //       </Grid.Row>
      //     </Grid>
      //     <Divider/>
      //     <div size="tiny" dangerouslySetInnerHTML={{__html: shortDescription}}/>
      //   </Card.Content>
      //   <Card.Content extra>
      //     { has_options ?
      //           <Link to={'/product/' + id}>
      //               <Button primary compact>Выбрать опции</Button>
      //           </Link> :
      //           <div>
      //               { <>
      //                   <Button primary size="big"  onClick={createProductLabel}>
      //                     Напечатать этикетку &nbsp;
      //                       <Icon name="print"/>
      //                   </Button>
      //                   {modalProductLabel()}
      //                   </>
      //               }
      //               <Link to={'/product/' + id}>
      //                   <Button secondary size="big" >Подробнее</Button>
      //               </Link>
      //           </div>
      //     }
      //   </Card.Content>
      // </Card>

  );
}
