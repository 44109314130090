import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import config from '../../config/config';
import {Segment, Header, Button, Icon, Label, Divider} from 'semantic-ui-react';
import {getCart} from './reducer';
import CartProduct from './CartProduct';
import {removeAllProductsFromCart} from "./actions";

export default function Cart(props) {

  const {userLocation, userData, mixpanel, dispatch} = props;
  const cart = useSelector((state) => getCart(state.cart));

  //console.log('cart', cart, cart[0]);

  useEffect(() => {
    if (mixpanel)
      mixpanel.track('Cart View', { ...userLocation, ...userData, cart });
    window.scrollTo(0, 0);
  }, []);

  return _.isEmpty(cart) ? (
      <Segment textAlign="center">Вы не выбрали заказы на взвешивание</Segment>
    ) : (
      <div>
        <span className='orders-text'>Платформа мультивзвешивания</span>
          <div className='text-cart-box'>
              <b className='text-about-cart'>Заказ на производство:</b> { cart[0].source_plant_order_id } от {cart[0].order_plant_date}
              <br/>
              <b className='text-about-cart'>Заказ покупателя:</b> { cart[0].source_customer_order_id }
              <br/>
              <b className='text-about-cart'>Продукт:</b> {cart[0].source_product_name}
              <br/>
              <b className='text-about-cart'>Спецификация:</b> {cart[0].spec_name}
          </div>
        <Segment className="cart-control-segment">
          <Button as='div' labelPosition='right' className="cart-control-remove-all-button">
            <Button color='red' onClick={() => dispatch(removeAllProductsFromCart())}>
              <Icon name='trash' />
              Удалить все
            </Button>
            <Label as='a' basic color='red' pointing='left'>
              {cart.length} шт.
            </Label>
        </Button>
        </Segment>

          <Divider fitted className='divider-cart'/>

          <div className="cart-wrapper">
                {cart.map((order) => (
                  <CartProduct
                    key={order.id}
                    order={order}
                    {...props}
                  />
                ))}
          </div>
        <Link to= { cart.length <= config.MAX_PRODUCTS_IN_CART ? "/multi-scale" : "#" }>
          <Button
              className="goto-checkout"
              size="big"
              color="green"
              fluid
              disabled={ cart.length > config.MAX_PRODUCTS_IN_CART }
          >
            Начать взвешивание
          </Button>
        </Link>
      </div>
    );
}
